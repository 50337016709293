import React from "react";
import domestic from "../../img/home-chat/domestic_worker_3.svg";
const CoreFeatures = () => {
  return (
    <section className="chat_core_features_area sec_pad">
      <div className="container">
        <div className="hosting_title chat_title text-center">
          <h2 className="wow fadeInUp" data-wow-delay="0.2s">
            <strong>What do you need?</strong>
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="chat_features_item wow fadeInUp">
              <div className="round">
                
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/box.png")}
                  alt=""
                />
                <img src={require("../../img/home-chat/domestic_1.png")} alt="" />
              </div>
              <a href=".#">
             
              </a>
              {/* <p>
                Tidio lets you get all your live chat, e-mail, and Messenger
                communication in one panel.
              </p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="chat_features_item wow fadeInUp">
              <div className="round">
                
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/pluse.png")}
                  alt=""
                />
                <img src={require("../../img/home-chat/garden_1.png")} alt="" />
              </div>
              <a href=".#">
                
              </a>
              {/* <p>
                Tidio lets you get all your live chat, e-mail, and Messenger
                communication in one panel.
              </p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.2s">
              <div className="round">
                
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/triangle.png")}
                  alt=""
                />
                <img src={require('../../img/home-chat/electrician_1.png')} alt="" />
              </div>
              <a href=".#">
                
              </a>
              {/* <p>
                Tidio lets you get all your live chat, e-mail, and Messenger
                communication in one panel.
              </p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.4s">
              <div className="round">
                {/* <div className="round_circle three"></div> */}
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/box.png")}
                  alt=""
                />
                <img src={require('../../img/home-chat/tutor.png')} alt="" />
              </div>
              <a href=".#">
               
              </a>
              {/* <p>
                Tidio lets you get all your live chat, e-mail, and Messenger
                communication in one panel.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CoreFeatures;
