import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import ChatBanner from "../components/Banner/ChatBanner";
import CoreFeatures from "../components/Features/CoreFeatures";
import ChatFeatures from "../components/Features/ChatFeatures";
import ChatIntegration from "../components/ChatIntegration";
import ChatClients from "../components/chatClients";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import CoreFeatures2 from "../components/Features/CoreFeatures2";

const homechat = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        nClass="m-auto"
        hbtnClass="new_btn"
      />
      <ChatBanner />
      <CoreFeatures />
      <ChatFeatures rowClass='flex-row-reverse' pClass="pr_70" col1="col-lg-6 offset-lg-1" col2="col-lg-5" icon="chat_icon.png" img1="dot.png" img2="works.png" img3="skyp_2.png" titleSmall="How the Daily App works?" 
      p="The daily app serves as a digital gateway for you to find the help you need! Choose from any of
      the above services to make your life easier." url="#"/>
      <ChatFeatures  pClass="pl_70" col1="col-lg-6" col2="col-lg-5 offset-lg-1" icon="video_icon.png" img1="dot.png" img2="service.png" img3="skyp_2.png" titleSmall="What is our service?" 
      p="We are a digital platform who’s aim it is to make finding a service provider easier than ever
      before. We facilitate in helping you find anything for your DAILY needs. From domestic workers to
      maths tutors , we will provide you with multiple options to assist in making life simpler." url="#"/>
      <CoreFeatures2 style = {"margin-top: 50px"}/>
      <ChatIntegration />
      {/* <ChatClients/> */}
      {/* <FooterErp fClass="event_footer_area" FooterData={FooterData} /> */}
      <Footer FooterData={FooterData}/>
    </div>
  );
};
export default homechat;
